import React from 'react';


const Archive = () => {
    return ( 
        <div>
            <h1>Archive</h1>
        </div>
     );
}
 
export default Archive;