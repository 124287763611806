import { Routes, Route } from 'react-router-dom';
import Navigation from './Components/Navigation/Navigation';
import Home from './Components/Home/Home';
import BetSlip from './Components/BetSlip/BetSlip';
import Archive from './Components/Archive/Archive';
import Login from './Components/Login/Login';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Container maxWidth="xl">
        <Box sx={{ bgcolor: '#111927', height: '100vh', borderRadius:'12px', marginTop:'50px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bet-slip" element={<BetSlip />} />
            <Route path="/archive" element={<Archive />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Box>
      </Container>
    </div>
  );
}

export default App;
