import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div>
      <h1 className="welcomeHeading">
        Welcome to <span style={{ color: '#9E77ED' }}>F</span>
        <span style={{ color: '#80E561' }}>Predictor.</span>
      </h1>
      <p className="welcomeText">
        Here we aim to provide you with what we bet on for the day. Please be aware this is not "fixed" matches or
        whatsoever. Here we give you purely the matches we bet on. Nothing More. So please use this information
        carefully. This information is not guarantied. Gambling can be addictive, so please be aware and careful.
      </p>

      <div className='accessButtons'>
        <button className='registerButton'>Register</button>
        <button className='loginButton'>Login</button>
      </div>
    </div>
  );
};

export default Home;
